import React, { useCallback, useEffect, useState } from 'react';
import ReactGA from '../utils/google-analytics';
import AuthWrapper from '../components/layout/AuthWrapper';
import Head from '../components/layout/Head';
import Layout from '../components/layout/Layout';
import { Jwt, UserClaims, getUserClaims, storeJwt } from '../utils/jwt-decode';
import EmployerDashboard from '../components/organisms/employer-dashboard/EmployerDashboard';
import FormTemplates from '../components/organisms/form-list/FormList';
import { fetchAPI, logout } from '../utils/fetch-api';
import LoggingIn from '../components/organisms/logging-in/logging-in';

const isBrowser = typeof window !== 'undefined';

const DashboardPage = () => {
  const [isLoggingIn, setLoggingIn] = useState(true);

  const getAuthToken = useCallback(async () => {
    const res = await fetchAPI<{ account: Jwt; success: boolean }>({
      method: 'GET',
      path: 'token',
    });

    if (res.success && res.body.success) {
      storeJwt(new UserClaims(res.body.account));
      setLoggingIn(false);
    } else {
      logout();
    }
  }, []);

  useEffect(() => {
    getAuthToken();
  }, []);

  if (isLoggingIn) {
    return <LoggingIn></LoggingIn>;
  }

  if (isBrowser) {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  const user = getUserClaims();
  if (!user) {
    logout();
    return <LoggingIn></LoggingIn>;
  }
  if (user.role === 'employer') {
    return (
      <AuthWrapper userRole="employer">
        <Head title="Employer Dashboard" />
        <Layout hideSidebar>
          <EmployerDashboard />
        </Layout>
      </AuthWrapper>
    );
  }
  if (user.role === 'admin') {
    return (
      <AuthWrapper userRole="admin">
        <Head title="Form templates" />
        <Layout>
          <FormTemplates />
        </Layout>
      </AuthWrapper>
    );
  }
  return <AuthWrapper />;
};

export default DashboardPage;
