import styled, { keyframes } from 'styled-components';

export const LoggingInWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  svg {
    max-width: 30%;
  }

  span {
    font-size: 1.5rem;
    line-height: 1.5;
    margin-top: 1rem;
  }
`;

const rotateAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dashAnimation = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;

  svg {
    width: 10rem;
    height: 10rem;
    animation: ${rotateAnimation} 2s linear infinite;
  }

  circle {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: ${dashAnimation} 1.5s ease-in-out infinite;
  }
`;
