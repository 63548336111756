import React from 'react';
import { useClient, useUser } from '../../../context-providers/user/user-hooks';
import { formatDate } from '../../../utils/dates';
import { usePagedRequest } from '../../../utils/paged-request';
import { LinkButton } from '../../atoms/button/Button';
import Paging from '../../atoms/paging/Paging';
import Stack from '../../atoms/stack/Stack';
import Table from '../../atoms/table/Table';
import { FullWidthContainer } from '../../layout/Layout.style';
import { EmployerPagedFormAssignment } from '../../../context-providers/forms/employer-paged-form-assignment';

const EmployerDashboard = () => {
  const { user } = useUser();
  const reviewForms = usePagedRequest<EmployerPagedFormAssignment>(
    'v2/forms/search/formassignments/employer',
    {
      sortColumnName: 'lastModified',
    },
  );
  const client = useClient();

  const renderRow = (data: EmployerPagedFormAssignment) => [
    <div key="1">
      <p>{data.sectorName}</p>
      <small>{data.courseName}</small>
    </div>,
    data.formName || '',
    data.learnerName || '',
    formatDate(data.learnerDateSent) || '',
    data.learnerFormAssignmentStatus === 3 &&
    (data.employerFormAssignmentStatus === 1 ||
      data.employerFormAssignmentStatus === 4 ||
      data.employerFormAssignmentStatus === 5) ? (
      <LinkButton
        to={`/complete-form/?fid=${data.id}`}
        dataQa={`complete-form-${data.formName}`}
        id={data.id}
      >
        Complete form
      </LinkButton>
    ) : (
      ''
    ),
  ];

  if (!user) {
    return null;
  }

  return (
    <FullWidthContainer>
      <Stack stackMultiplier={2}>
        <Stack stackMultiplier={1}>
          <h1>
            {user.firstName} {user.lastName}
            {client.data?.name && <span>({client.data?.name || ''})</span>}
          </h1>
          <p>You can review or sign any active forms.</p>
        </Stack>
        <Table
          caption="List of applications to review"
          data={reviewForms.data}
          headerItems={[
            { sortColumnName: 'sectorName', label: 'Sector/course' },
            { sortColumnName: 'template.formName', label: 'Form name' },
            { sortColumnName: 'learnerName', label: 'Applicant' },
            { sortColumnName: 'lastModified', label: 'Date sent' },
            { label: '' },
          ]}
          renderRow={renderRow}
          paged={reviewForms.pagedParams}
        />
        <Paging pageCount={reviewForms.pageCount} />
      </Stack>
    </FullWidthContainer>
  );
};

export default EmployerDashboard;
