import React from 'react';
import { color } from '../../config';
import Logo from '../../images/Logo';
import { LoggingInWrapper, SpinnerWrapper } from './logging-in.style';

const LoggingIn = () => {
  return (
    <LoggingInWrapper>
      <Logo fill={color.primary.base} responsive />
      <SpinnerWrapper>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          aria-hidden="true"
          focusable="false"
        >
          <circle
            cx="16"
            cy="16"
            r="14.5"
            fill="none"
            stroke={color.primary.base}
            strokeWidth="3"
            strokeMiterlimit="10"
          />
        </svg>
      </SpinnerWrapper>
    </LoggingInWrapper>
  );
};

export default LoggingIn;
